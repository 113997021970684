<template>
    <div>
      <a-spin class="d-flex justify-content-center" v-if="!listData">
        <a-icon slot="indicator" type="loading" style="font-size: 24px" spin />
      </a-spin>
      <ul v-else :class="'list-group list-group-flush ulgroup_' + type" v-for="(item,index) in list" :key="index">
        <li class="list-group-item treeContainer" @click="expand($event, index)"
          :style="item.selected ? 'background-color: rgb(237 237 243 / 38%);' : ''">
          <span class="cpos">
            <input type="checkbox" class="global-checkbox" v-model="item.enabled" :id="type + 'global_check_' + index" @click="globalEnable($event, index)"/>
          </span>
          <span :class="'col-sm cpos arrow ' + (item.Versions.length > 0 ? 'left' : '')">{{item.Family}}</span>
          <span class="col-sm cpos" @click="$event.stopPropagation()" v-if="type === 'browser'">
            <a-tooltip placement="top">
              <template slot="title">
                <span>Include all new browser versions automatically</span>
              </template>
              <label class="mr-1">Auto include new versions:
                <input type="checkbox" class="ml-1 checkbox" v-model="item.AutoIncludeNewBrowsers">
              </label>
            </a-tooltip>
          </span>
          <span class="col-sm cpos-half text-center" @click="$event.stopPropagation()">
            <label class="mr-1 no-margin">Bid Adjustment:
              <span class="valuePadding"><input type="number" min="0" max="100" class="form-control bid-adjustment ml-1" @keyup="calculatedBid($event, null)" v-model="item.BidAdjustment">%</span>
            </label>
          </span>
          <span class="col-sm cpos">
            <label for="" class="mr-1">Calculated Bid: </label>
            <span class="calculatedBid">{{ typeof item.CalculatedBid === 'undefined' ? '' : Math.round(item.CalculatedBid*100000) / 100000 }}</span>
          </span>
        </li>
        <!-- Versions -->
        <ul class="list-group list-group-flush ml-5" :id="type + 'sub_versions_'+index" style="display: none;" v-if="item.Versions.length > 0">
          <li class="list-group-item treeContainerV" v-if="item.Versions.length > 0">
            <span class="col-sm"><strong>OS Versions</strong></span>
            <span class="col-sm"><strong>Bid Adjustment</strong></span>
            <span class="col-sm"><strong>Calculated Bid</strong></span>
          </li>
          <li class="list-group-item treeContainerV" v-for="subVersion in item.Versions" :key="subVersion.Major+(typeof subVersion.Minor !== 'undefined' ? subVersion.Minor : '' )"
            :style="subVersion.selected ? 'background-color: rgb(237 237 243 / 38%);' : ''">
            <span class="col-sm cpos">
              <label>
                <input type="checkbox" v-model="subVersion.enabled" class="version-checkbox" @click="enableVersion($event, index)"/>
                {{item.Family}} {{subVersion.Major + (typeof subVersion.Minor === 'undefined' || subVersion.Minor === '' ? '' : '.' + subVersion.Minor)}}
              </label>
            </span>
            <span class="col-sm">
              <span class="valuePadding"><input type="number" placeholder="0" class="form-control bid-adjustment ml-1 no-border" @keyup="calculatedBid($event, null)" v-model="subVersion.BidAdjustment">%</span>
            </span>
            <span class="col-sm cpos calculatedBid">
              {{ typeof subVersion.CalculatedBid === 'undefined' ? '' : Math.round(subVersion.CalculatedBid*100000) / 100000 }}
            </span>
          </li>
        </ul>
      </ul>
    </div>
</template>

<script>
import Vue from 'vue'
import { mapState } from 'vuex'

export default {
  data() {
    return {
      checkedColor: 'rgb(237 237 243 / 38%)',
    }
  },
  model: {
    prop: 'list',
    event: 'change',
  },
  computed: {
    ...mapState(['info']),
    list() {
      const options = this.type === 'os' ? this.info.oss : this.info.browsers
      options.forEach(element => {
        // Set initial values
        element.BidAdjustment = 100
        element.CalculatedBid = 0
        element.enabled = false
        element.selected = false
        element.AutoIncludeNewBrowsers = false
        // Match provided data
        const concrete = this.listData.filter(el => el.Family === element.Family)
        if (concrete.length === 1) {
          element.BidAdjustment = concrete[0].BidAdjustment
          element.CalculatedBid = concrete[0].CalculatedBid
          element.enabled = element.Versions.length === concrete[0].Versions.length
          element.selected = element.Versions.length === concrete[0].Versions.length || concrete[0].Versions.length > 0
          if (typeof concrete[0].AutoIncludeNewBrowsers !== 'undefined') element.AutoIncludeNewBrowsers = concrete[0].AutoIncludeNewBrowsers === 1
        }
        // Populate versions
        element.Versions.length > 0 && element.Versions.forEach((version) => {
          // Set defaults
          version.BidAdjustment = 100
          version.CalculatedBid = 0
          version.enabled = false
          version.selected = false
          if (concrete.length === 1) {
            // Get concrete value
            const value = concrete[0].Versions.filter(el => {
              const hasMinor = typeof el.Minor === 'undefined'
              return el.Major === version.Major && (hasMinor || el.Minor === version.Minor)
            })
            if (value.length > 0) {
              version.BidAdjustment = value[0].BidAdjustment
              version.CalculatedBid = value[0].CalculatedBid
              version.enabled = true
              version.selected = true
            }
          }
        })
      })
      this.$emit('change', options)
      return options
    },
  },
  methods: {
    expand(event, index) {
      const subGroup = document.querySelector(`#${this.type}sub_versions_${index}`)

      // Skip with empty versions
      if (subGroup === null) return

      const display = subGroup.style.display === 'none' ? 'block' : 'none'
      subGroup.style.display = display

      // Toggle list arrow
      const container = this.$_getContainer(event.target)
      container.childNodes[1].classList.toggle('left')
      container.childNodes[1].classList.toggle('down')
    },
    /**
     * Check/Uncheck all version for a group
     */
    globalEnable(event, index) {
      event.stopPropagation()

      event.target.removeAttribute('indeterminate')

      const versionContainer = sel(`#${this.type}sub_versions_${index}`)
      if (versionContainer !== null) {
        versionContainer.style.display = 'block'
      }

      // Select/Deselect all children
      const container = this.$_getContainer(event.target, 'UL')
      const versions = container.querySelector('UL')
      if (versions !== null) {
        // Point arrow
        this.$_getContainer(event.target).childNodes[1].classList = 'col-sm cpos arrow down'

        // check/uncheck versions
        const checkboxes = versions.querySelectorAll('input[type="checkbox"]')
        checkboxes.length !== null && checkboxes.forEach((checkbox) => {
          checkbox.checked = event.target.checked
          this.list[index].Versions.forEach((sVer) => {
            sVer.enabled = event.target.checked
          })
          this.$_getContainer(checkbox).style.backgroundColor = checkbox.checked ? this.checkedColor : ''
        })

        // Recalculate bid
        const inputs = versions.querySelectorAll('input[type="number"]')
        inputs.length > 0 && inputs.forEach((item) => {
          this.calculatedBid(null, item)
        })
      }

      // Color row
      this.$_getContainer(event.target).style.backgroundColor = event.target.checked ? this.checkedColor : ''

      // Recalculate Bid
      const input = container.querySelector('input[type="number"]')
      if (input !== null) {
        this.calculatedBid(null, input)
      }
    },
    /**
     * Enable/Disable versions
     */
    enableVersion(event, index) {
      const globalCheck = sel(`#${this.type}global_check_${index}`)
      const versionContainer = this.$_getContainer(event.target, 'UL')
      const versions = versionContainer.querySelectorAll('input[type="checkbox"]')

      // Get selected versions count
      let counter = 0
      versions !== null && versions.forEach((version) => {
        counter += version.checked ? 1 : 0
      })

      const globalRow = this.$_getContainer(globalCheck)
      const currentRow = this.$_getContainer(event.target)
      currentRow.style.backgroundColor = event.target.checked ? this.checkedColor : ''

      // Change global checkbox
      if (counter === 0) {
        globalCheck.checked = false
        globalCheck.indeterminate = false
        globalRow.style.backgroundColor = ''
      } else if (counter === versions.length) {
        globalCheck.checked = true
        globalCheck.indeterminate = false
        globalRow.style.backgroundColor = this.checkedColor
      } else {
        globalCheck.indeterminate = true
        globalRow.style.backgroundColor = this.checkedColor
      }

      // Recalculate bid
      const input = currentRow.querySelector('input[type="number"]')
      this.calculatedBid(null, input)
    },
    /**
     * Render colors and checkbox
     */
    postProcess() {
      this.list.length > 0 && this.list.forEach((element, index) => {
        const globalEnable = sel(`#${this.type}global_check_${index}`)
        const selected = element.Versions.filter(el => el.enabled).length

        // Set global checkbox to indeterminate mode
        if (selected > 0 && selected < element.Versions.length) {
          globalEnable.indeterminate = true
        }
      })
    },
    /**
     * Recalculate bid
     */
    calculatedBid(event, obj = null) {
      const object = obj === null ? event.target : obj
      const calculatedHolder = this.$_getContainer(object).querySelector('.calculatedBid')

      if (object.value === '' || object.value === 0) {
        calculatedHolder.innerText = 0
      } else {
        calculatedHolder.innerText = Math.round((object.value / 100) * this.defaultVal * 100000) / 100000
      }
      // Set calculated bid to default when row not enabled
      // if (enabled !== null && enabled === false) {
      //   calculatedHolder.innerText = this.defaultVal
      // }
    },
    $_getContainer(el, tag = 'LI') {
      return el.tagName === tag ? el : this.$_getContainer(el.parentElement, tag)
    },
  },
  updated() {
    Vue.nextTick(() => { this.postProcess() })
  },
  props: {
    type: {
      type: String,
      default: () => '',
    },
    listData: {
      type: Array,
      default: () => [],
    },
    defaultVal: {
      type: Number,
      default: () => 0,
    },
  },
  created() {
    Vue.nextTick(() => { this.postProcess() })
  },
  watch: {
    'info.oss': {
      immediate: true,
      deep: true,
      handler(newValue, oldValue) {
        Vue.nextTick(() => {
          const oss = document.querySelectorAll('.ulgroup_os input[type=number]')
          oss.length > 0 && oss.forEach((el) => {
            // console.log(el, 'EL')
            this.calculatedBid(null, el)
          })
        })
      },
    },
    'info.browsers': {
      immediate: true,
      deep: true,
      handler(newValue, oldValue) {
        Vue.nextTick(() => {
          const browsers = document.querySelectorAll('.ulgroup_browser input[type=number]')
          browsers.length > 0 && browsers.forEach((el) => {
            this.calculatedBid(null, el)
          })
        })
      },
    },
    defaultVal: {
      immediate: true,
      deep: true,
      handler(newValue, oldValue) {
        const oss = document.querySelectorAll('.ulgroup_os input[type=number]')
        oss.length > 0 && oss.forEach((el) => {
          this.calculatedBid(null, el)
        })
        const browsers = document.querySelectorAll('.ulgroup_browser input[type=number]')
        browsers.length > 0 && browsers.forEach((el) => {
          this.calculatedBid(null, el)
        })
      },
    },
  },
}

/**
 * JS selector wrapper
 */
function sel(selector) {
  return document.querySelector(selector)
}

</script>

<style scoped>
  .valuePadding {
      background-color: #ffffff;
      border-radius: 4px;
      padding-right:10px;
      border: #e9edf3 1px solid;
  }
  .valuePadding input {
    border: none;
    background-color: transparent;
    margin-top: 0;
  }
  .valuePadding .bid-adjustment {
    padding-bottom: 0px;
    padding-top: 0px;
  }
  .arrow.left::before {
    content: "▶";
    height: 10px;
    margin-right: 5px;
  }
  .arrow.down::before {
    content: "▼";
    height: 10px;
    margin-right: 5px;
  }
  .list-group-item {
    cursor: pointer;
  }
  .bid-adjustment {
    display: inline;
    width: 80px !important;
    padding: 0 5px;
    font-size: 0.9rem;
    height: 1.7rem;
  }
  .checkbox {
    position: relative;
    top: 2px;
  }
  .list-group-item {
    padding: .1rem 1.25rem !important;
  }
  .treeContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    /* flex-wrap: wrap; */
  }
  .treeContainerV {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
    /* flex-wrap: wrap; */
  }
  label {
    opacity: 0.8;
  }
  .no-border {
    border: none;
  }
  .list-group-flush > .list-group-item:last-child {
    border-bottom-width: 1px !important;
  }
  .global-checkbox {
    position: relative;
    top: 2px;
  }
  .cpos-half {
    position: relative;
    top: 2px;
  }
  .cpos {
    position: relative;
    top: 4px;
  }
  .version-checkbox {
    position: relative;
    margin-right: 5px;
    top: 2px;
  }
  .no-margin {
    margin-bottom: 0;
  }
</style>
